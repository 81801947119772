import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: JSON.parse(sessionStorage.getItem('token')) || false,
    // loginSelected: JSON.parse(sessionStorage.getItem('loginSelected')) || false,
    vuexCommands: JSON.parse(sessionStorage.getItem('vuexCommands')) || [],
    // vuexAccount: JSON.parse(sessionStorage.getItem('vuexAccount')) || {},
    vuexAccountInfo: JSON.parse(sessionStorage.getItem('vuexAccountInfo')) || {},

    vuexHealerInfo: JSON.parse(sessionStorage.getItem('vuexHealerInfo')) || {},

    vuexRecordPlan: JSON.parse(sessionStorage.getItem('vuexRecordPlan')) || {},
    vuexRecordId: JSON.parse(sessionStorage.getItem('vuexRecordId')) || "",
    vuexRecordInfo: JSON.parse(sessionStorage.getItem('vuexRecordInfo')) || {},

    vuexTrainInfo: JSON.parse(sessionStorage.getItem('vuexTrainInfo')) || {},

    // 添加新客户 指标录入返回时 回显数据(基础信息表单提交时触发， 指标录入表单提交与基础信息表单页返回/治疗者档案初始化时 删除清空)


    // 信息处理页publicHandleView的 路由来源
    vuexHandleRouterSource: JSON.parse(sessionStorage.getItem('vuexHandleRouterSource')) || "filesView",

    // excel导入 编译的数组
    vuexShiftExcelTransformList: JSON.parse(sessionStorage.getItem('vuexExcelTransformList')) || [],
    vuexTimeExcelTransformList: JSON.parse(sessionStorage.getItem('vuexTimeExcelTransformList')) || [],
    bvuexShiftExcelTransformList: JSON.parse(sessionStorage.getItem('bvuexExcelTransformList')) || [],
    bvuexTimeExcelTransformList: JSON.parse(sessionStorage.getItem('bvuexTimeExcelTransformList')) || [],
  },
  mutations: {
    updateLoginSelected(state, payload) {
      state.loginSelected = payload
      sessionStorage.setItem('loginSelected', JSON.stringify(payload))
    },
    updateToken(state, payload) {
      state.token = payload
      sessionStorage.setItem('token', JSON.stringify(payload))
    },
    //命令数组
    updateCommands(state, payload) {
      state.vuexCommands = payload
      sessionStorage.setItem('vuexCommands', JSON.stringify(payload))
    },

    // 用户信息
    updateAccountInfo(state, payload) {
      state.vuexAccountInfo = payload
      sessionStorage.setItem('vuexAccountInfo', JSON.stringify(payload))
    },
    //登录的账号密码
    updateAccount(state, payload) {
      state.vuexAccount = payload
      sessionStorage.setItem('vuexAccount', JSON.stringify(payload))
    },
    // 治疗者档案 - 治疗者详情
    updateHealerInfo(state, payload) {
      state.vuexHealerInfo = payload
      sessionStorage.setItem('vuexHealerInfo', JSON.stringify(payload))
    },
    // 治疗者档案 - 治疗者治疗记录 - 治疗计划(All)
    updateRecordPlan(state, payload) {
      state.vuexRecordPlan = payload
      sessionStorage.setItem('vuexRecordPlan', JSON.stringify(payload))
    },
    // 治疗者档案 - 治疗者治疗记录 id
    updateRecordId(state, payload) {
      state.vuexRecordId = payload
      sessionStorage.setItem('vuexRecordId', JSON.stringify(payload))
    },
    // 治疗者档案 - 治疗者治疗记录 详情(信息+指标)
    updateRecordInfo(state, payload) {
      state.vuexRecordInfo = payload
      sessionStorage.setItem('vuexRecordInfo', JSON.stringify(payload))
    },
    // 无感肌群训练 - 治疗者 详情
    updateTrainInfo(state, payload) {
      state.vuexTrainInfo = payload
      sessionStorage.setItem('vuexTrainInfo', JSON.stringify(payload))
    },

    // 添加新客户 指标录入返回时 回显数据(基础信息表单提交时触发， 指标录入表单提交与基础信息表单页返回/治疗者档案初始化时 删除清空)
    // updateTrainInfo(state,payload){
    //   state.vuexTrainInfo = payload
    //   sessionStorage.setItem('vuexTrainInfo', JSON.stringify(payload))
    // },

    // 信息处理页publicHandleView的 路由来源
    updateHandleRouterSource(state, payload) {
      state.vuexHandleRouterSource = payload
      sessionStorage.setItem('vuexHandleRouterSource', JSON.stringify(payload))
    },
    // excel导入 编译的数组 shift
    updateShiftExcelTransformList(state, payload) {
      state.vuexShiftExcelTransformList = payload
      sessionStorage.setItem('vuexShiftExcelTransformList', JSON.stringify(payload))
    },
    // excel导入 编译的数组 time
    updateTimeExcelTransformList(state, payload) {
      state.vuexTimeExcelTransformList = payload
      sessionStorage.setItem('vuexTimeExcelTransformList', JSON.stringify(payload))
    },
    bupdateShiftExcelTransformList(state, payload) {
      state.bvuexShiftExcelTransformList = payload
      sessionStorage.setItem('bvuexShiftExcelTransformList', JSON.stringify(payload))
    },
    bupdateTimeExcelTransformList(state, payload) {
      state.bvuexTimeExcelTransformList = payload
      sessionStorage.setItem('bvuexTimeExcelTransformList', JSON.stringify(payload))
    },

  },
  getters: {
    token: state => state.token,
    loginSelected: state => state.loginSelected, //登录页面记住密码
    vuexCommands: state => state.vuexCommands, //命令数组
    vuexAccountInfo: state => state.vuexAccountInfo,//用户信息 .id  .type(1管,2普)
    vuexAccount: state => state.vuexAccount,//登录页面账号密码
    vuexHealerInfo: state => state.vuexHealerInfo,//客户的信息（治疗者档案列表进入）

    vuexRecordPlan: state => state.vuexRecordPlan,//治疗方案 行 总信息（治疗方案详情）
    vuexRecordId: state => state.vuexRecordId,//治疗者治疗记录 id(用于修改 治疗记录中 用户基本信息、指标)
    vuexRecordInfo: state => state.vuexRecordInfo,//治疗方案中 基本信息及指标

    vuexTrainInfo: state => state.vuexTrainInfo,

    vuexHandleRouterSource: state => state.vuexHandleRouterSource,// 信息处理页publicHandleView的 路由来源

    vuexShiftExcelTransformList: state => state.vuexShiftExcelTransformList,// excel导入 编译的数组
    vuexTimeExcelTransformList: state => state.vuexTimeExcelTransformList,
    bvuexShiftExcelTransformList: state => state.bvuexShiftExcelTransformList,
    bvuexTimeExcelTransformList: state => state.bvuexTimeExcelTransformList,
  },
})
// this.$store.commit("", )
// import {mapGetters} from "vuex"
// computed:{
//   ...mapGetters([
//     "",
//   ]),}