<template>
  <div class="main">

    <div class="bgc">
      <img src="@/assets/imgAll/loginBg.jpg" alt="">
    </div>

    <div class="lrlayout">
      <div class="left">
        <!-- 用于50%布局 -->
      </div>
      <div class="right">
        <div class="h3">
          <h3>大芈智能无感肌群</h3>
          <h3>训练系统</h3>
        </div>
        <div v-if="!isReset" class="login">
          <h4>用户登录</h4>
          <el-form :model="vuexAccount" status-icon :rules="rules" ref="vuexAccount"  class="demo-vuexAccount" >
            <el-form-item  prop="account">
              <el-input  v-model="vuexAccount.account" autocomplete="off" placeholder="请输入登录ID"  prefix-icon="el-icon-user-solid"></el-input>
            </el-form-item>
            <el-form-item  prop="password" >
              <el-input type="password" v-model="vuexAccount.password" autocomplete="off" placeholder="请输入密码"  prefix-icon="el-icon-lock"></el-input>
            </el-form-item>
            <div class="handle">
               <el-checkbox v-model="loginSelected">记住密码</el-checkbox>
               <!-- <div class="passHandle" @click="forgetClick">找回密码</div> -->
            </div>
            <el-form-item>
              <el-button  @click="submitForm('vuexAccount')">登录</el-button>
            </el-form-item>
            <div class="handle">
               <!-- <el-checkbox v-model="checked">记住密码</el-checkbox> -->
               <a class="reg" @click="registerClick()">注册账号</a>
               <!-- <div class="passHandle" @click="forgetClick">找回密码</div> -->
            </div>
          </el-form>
        </div>
        <div v-else class="resetPassword">

        </div>

      </div>

    </div>

  </div>
</template>

<script>
import { AXIOScustomerLogin } from '@/api/customer'
export default {
  data(){
    var validateAccount = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入账号'));
        return 
      } 
      callback();
    };
    var validatePassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
        return 
      } 
      if (value.length < 6 || value.length > 16) {
        callback(new Error('密码长度要求为6-16位'));
        return 
      }
      callback();
    };
    return {
      isReset:false,//切换找回密码页
      loginSelected:false,//记住密码
      vuexAccount:{
        account:"",
        password:"",
      },
      rules:{
        account:[
          {validator: validateAccount, trigger: 'blur'}
        ],
        password:[
          {validator: validatePassword, trigger: 'blur'}
        ],
      }
    }
  },

  mounted() {
    this.loginSelected = localStorage.getItem('updateLoginSelected') === 'true';
    if (this.loginSelected) {
      let obj = localStorage.getItem('updateAccount')
      this.vuexAccount = JSON.parse(obj);  
    }
  },

  methods:{
    forgetClick(){
      console.log("忘记密码");
      this.$router.push({name:"ForgetView"}).catch(err => {
        console.log('router err')
      })
    },
    registerClick(){
      console.log("注册账号");
      this.$router.push({name:"RegisterView"}).catch(err => {
                  console.log('router err')
      })
    },
    submitForm(formName){
      this.$refs[formName].validate(
        async (valid)=>{
          if(valid){
            try {
              const {data} = await AXIOScustomerLogin({
                customerPhone:this.vuexAccount.account,
                customerPassword:this.$md5(this.vuexAccount.password)
              })
              
              // 提交成功跳转至首页
              if(data.code === 200){
                // 存入vuex 登录token 
                this.$store.commit("updateToken",true)
                //存入vuex 用户信息
                this.$store.commit("updateAccountInfo",data.data)
                //保存记住密码状态
                // this.$store.commit("updateLoginSelected",this.loginSelected)
                localStorage.setItem("updateLoginSelected",this.loginSelected)
                //保存账号密码
                // this.$store.commit("updateAccount",)
                let obj = {account:this.vuexAccount.account,password:this.vuexAccount.password}
                localStorage.setItem("updateAccount",JSON.stringify(obj))
                // this.vuexAccountGetter = { account:"",password:"", }
                this.$message.success("登录成功")
                this.$router.push({name:"HomeView"}).catch(err => {
                  console.log('router err')
                })
                // console.log(data);
                return 
              }
              this.$message(data.msg)
            } catch (error) {
              console.log(error)
              this.$message("操作失败! 请重试")
            }
            
          }else{
            console.log("submit error")
            return false
          }
        }
      )
    },
    
  }
}
</script>

<style lang="less" scoped>
.main{
  min-width: 1300px;
}
.bgc{
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  img{
    width: 100%;
    min-width: 1300px;
    height: 100%;
  }
}
.lrlayout{
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;

}
.left{
  width: 50%;
  min-width: 650px;
}
.right{
  min-width: 652px;
  width: 50%;
  padding-top: 10%;
  padding-left: 10%;
  padding-bottom: 10%;
  padding-right: 10%;
  // margin-right: 10%;
  // margin-right: 10%;
  .h3{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    // min-width: 580px;
    min-width: 390px;
    font-size: 30px;
    margin-bottom: 30px;
    background: linear-gradient(270deg, #00E8D0 0%, #009DF4 100%);
    letter-spacing: 7px;
    background-clip: text;
    // text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
.login{
  // min-width: 580px;
  min-width: 390px;
  background-color:  rgba(62,115,199,0.2);
  border: 4px solid #337BCD;
  padding: 30px 40px;
  h4{
    font-size: 30px;
    color: #00D5DA;
    margin-bottom: 20px;
  }
  .handle{
    display: flex;
    justify-content: space-between;
    color: #FFFFFF;
    margin-bottom: 20px;
  }
}
.reg{
  background-color: transparent;
  border: none; /* 如果要去掉按钮的边框 */
  /* 可以根据需要添加其他样式 */
  color: #FFFFFF;
  cursor: pointer;
}
/deep/ .el-checkbox__label{
      color: #FFFFFF;
    }
/deep/ .el-input{
  
  border-radius: 5px;
  border: 2px solid #337BCD;
}
/deep/ .el-input__inner{
  background-color: rgba(41,79,165,0.3);
  color: #ffffff;
}
/deep/ .el-checkbox__inner{
  background: rgba(51,123,205,0.4);
}
/deep/ .el-button{
      width: 100%;
      background-color: #0BA1F8;
      color: #FFFFFF;
      font-size: 25px;
      height: 50px;
}

/deep/.el-form-item__content{
  margin-left: 0px !important;
}
/deep/.el-form-item:last-child{
  margin-top: 50px;
}

</style>