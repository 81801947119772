import request from "@/utils/request"

// import { AXIOScustomerList } from '@/api/customer'
// async onLogin () {
//   try {
//     const res = await AXIOScustomerList({})
//     console.log(res)
//     // this.$message.success("操作成功!")
//   } catch (err) {
//     // this.$message("操作失败 请重试!")
//   }
// }
/**
 * 客户信息
 */
// 客户列表
export const AXIOScustomerList = params => {
  return request({
    method: 'GET',
    url: '/platform/DmCustomerBaseInfo/list',
    params
  })
}

/**
 * 客户信息
 */
// 客户列表
export const AXIOScustomerList2 = params => {
  return request({
    method: 'GET',
    url: '/platform/DmCustomerBaseInfo/list2',
    params
  })
}

// // 客户列表
// export const AXIOScustomerListByPlan = params => {
//   return request({
//     method: 'GET',
//     url: '/platform/DmCustomerBaseInfo/listByCustomerId',
//     params
//   })
// }

// 删除客户
export const AXIOScustomerDel = params => {
  return request({
    method: 'GET',
    url: '/platform/DmCustomerBaseInfo/delCustomerBaseInfo',
    params
  })
}

// 客户登录
export const AXIOScustomerLogin = params => {
  return request({
    method: 'GET',
    url: '/platform/DmCustomerBaseInfo/dmCustomLogin',
    params
  })
}

// 新增客户-基本信息
export const AXIOSpushCustomerBaseInfo = params => {
  return request({
    method: 'POST',
    url: '/platform/DmCustomerBaseInfo/addInfo',
    params
  })
}

// 修改密码
export const AXIOSgetCustomerPassword = params => {
  return request({
    method: 'POST',
    url: '/platform/DmCustomerBaseInfo/updatePassWord',
    params
  })
}

// 获取客户 信息+指标
export const AXIOSgetCustomerInfo = params => {
  return request({
    method: 'POST',
    url: '/platform/DmCustomerBaseInfo/getInfo',
    params
  })
}

// 更改客户 信息+指标
export const AXIOSupdataCustomerInfo = data => {
  return request({
    method: 'POST',
    url: '/platform/DmCustomerBaseInfo/updateInfo',
    data
  })
}

// 获取全部干预模式
export const AXIOSupdataCustomerModelGetAll = data => {
  return request({
    method: 'POST',
    url: '/platform/DmInterveneModelInfo/getAll',
    data
  })
}

// 获取添加干预模式
export const AXIOSupdataCustomerModelAdd = params => {
  return request({
    method: 'POST',
    url: '/platform/DmInterveneModelInfo/addInterveneModel',
    params
  })
}

// 获取删除干预模式
export const AXIOSupdataCustomerModelDel = params => {
  return request({
    method: 'POST',
    url: '/platform/DmInterveneModelInfo/deleteInterveneModelById',
    params
  })
}

// 获取干预部位
export const AXIOSupdataCustomerPlanGetList = params => {
  return request({
    method: 'GET',
    url: '/platform/DmTreatmentPlan/list',
    params
  })
}

// 获取 非基础指标列表(传0)
export const AXIOSgetPushItemList = params => {
  return request({
    method: 'GET',
    url: '/platform/DmLabelConfig/list',
    params
  })
}

// 设置用户显示屏 号码( 显示屏id + 客户id )
export const AXIOSsetShowCode = params => {
  return request({
    method: 'POST',
    url: '/platform/DmCustomerBaseInfo/setShowNo',
    params
  })
}

// 获取对应显示屏 用户信息
export const AXIOSgetShowCustomerInfo = params => {
  return request({
    method: 'POST',
    url: '/platform/DmCustomerBaseInfo/getShowCustomerInfo',
    params
  })
}
